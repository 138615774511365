import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';

const StyledTitle = styled.h2`
  color: var(--color-slate);
  margin-top: 0;
  ${scalingSize('font-size', 24, 48)}
  line-height: 1.1;
  margin-bottom: 0.571428571em;
`;

const Title: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default Title;
