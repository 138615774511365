import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  padding-bottom: 1em;
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
