import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CaseItem from '$components/CaseItem';
import { CasesProps } from './types';

const BlockCaseListing: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        cases(sort: "created_at:desc") {
          id
          Slug
          Title
          ShortDescription
          Image {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 864)
              }
            }
          }
          Teaser {
            Heading
            Text
            Image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 864)
                }
              }
            }
          }
        }
      }
    }
  `);

  const { cases }: CasesProps = data.cms;

  return (
    <div>
      {cases.map((item) => (
        <CaseItem key={`caseItem${item.id}`} data={item} />
      ))}
    </div>
  );
};

export default BlockCaseListing;
