import React, { useState } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import { WrapMax } from '$components/Wraps';
import Button from '$components/Button';
import FadeInSection from '$components/FadeInSection';
import { CaseItemProps } from './types';
import Article from './Article';
import Figure from './Figure';
import Content from './Content';
import Title from './Title';

const StyledWrap = styled(WrapMax)`
  &:last-child {
    margin-bottom: 4em;
  }
`;

const CaseItem: React.FC<CaseItemProps> = ({ data }) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <StyledWrap>
      <FadeInSection>
        <AniLink to={`/case/${data.Slug}/`} bg="var(--color-citrus5)" cover direction="left">
          <Article onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {data.Image && (
              <Figure hover={hover}>
                <GatsbyImage image={getImageData(data.Image.imageFile)} alt={data.Image.alternativeText} />
              </Figure>
            )}
            <Content>
              <Title>{data.Title}</Title>
              <p>{data.ShortDescription}</p>
              <Button fake theme="ghost" size="small" showArrow>{`Case: ${data.Title}`}</Button>
            </Content>
          </Article>
        </AniLink>
      </FadeInSection>
    </StyledWrap>
  );
};

export default CaseItem;
